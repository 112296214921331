import Plotly from "plotly.js-dist";
import React from "react";

export interface PlotlyGraphProps {
  x_values: number[]
  y_values: number[]
  size?: number[]
  hide_y_label?: boolean
}

export class PlotlyGraph<P extends PlotlyGraphProps> extends React.Component<P> {
  element = React.createRef<HTMLDivElement>();
  empty_graph_message = "No data available.";

  get startX() {
    return 20;
  }

  get endX() {
    return Math.min(100, this.x[this.x.length - 1]);
  }

  get xStep() {
    return (this.endX - this.startX) / 100;
  }

  get yStep() {
    return this.maxY / 100;
  }

  extraAttrs() {
    return {
      margin: { l: 0, r: 0, b: 20, t: 0 },
      showlegend: false,
      xaxis: {
        color: "#909FAC",
        tickfont: {
          family: "Arial Black",
          size: 14
        },
        ...this.xAxisAttrs()
      },
      yaxis: {
        range: [0, Math.max(...this.y) * 1.1],
        showticklabels: !this.props.hide_y_label,
        ...this.yAxisAttrs()
      }
    };
  }

  xAxisAttrs() {
    return {};
  }

  yAxisAttrs() {
    return {};
  }

  get maxX(): number {
    return Math.max(...this.x);
  }

  get maxY(): number {
    return Math.max(...this.y);
  }

  config() {
    return {
      displayModeBar: false,
      responsive: true,
      autosize: true
    };
  }

  get x() {
    return this.props.x_values;
  }

  get y() {
    return this.props.y_values;
  }

  traces(): any[] {
    return [];
  }

  shapes(): any[] {
    return [];
  }

  annotations(): any[] {
    return [];
  }

  yAtPos(n) {
    const { x, y } = this;
    const closestX = JSON.parse(JSON.stringify(x)).sort((a, b) => Math.abs(n - a) - Math.abs(n - b))[0];
    return y[x.indexOf(closestX)];
  }

  get size() {
    return {
      width: this.element.current!.parentElement!.offsetWidth,
      height: this.element.current!.parentElement!.offsetHeight
    };
  }

  componentDidMount() {
    const { x, y } = this;
    if (!this.element.current) {
      return;
    }
    const annotations = this.annotations();
    const shapes = this.shapes();
    Plotly.newPlot(
      this.element.current,
      [{ x, y }, ...this.traces()],
      { annotations, shapes, ...this.extraAttrs() },
      this.config()
    );
  }

  emptyGraph(message = this.empty_graph_message) {
    const gridSizes = [20, 80];
    return (
      <div className="graph-empty h-100">
        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <pattern id="smallGrid" width={gridSizes[0]} height={gridSizes[0]} patternUnits="userSpaceOnUse">
              <path d={`M ${gridSizes[0]} 0 L 0 0 0 ${gridSizes[0]}`} fill="none" stroke="gray" strokeWidth="0.5" />
            </pattern>
            <pattern id="grid" width={gridSizes[1]} height={gridSizes[1]} patternUnits="userSpaceOnUse">
              <rect width={gridSizes[1]} height={gridSizes[1]} fill="url(#smallGrid)" />
              <path d={`M ${gridSizes[1]} 0 L 0 0 0 ${gridSizes[1]}`} fill="none" stroke="gray" strokeWidth="1" />
            </pattern>
          </defs>
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            style={{ fontSize: "11pt", fontFamily: "Avenir", fill: "gray" }}
          >
            {message}
          </text>
          <rect width="100%" height="100%" fill="url(#grid)" />
        </svg>
      </div>
    );
  }

  render() {
    if (!this.x.length) {
      return this.emptyGraph();
    }
    return <div ref={this.element} style={{ width: "100%", height: "100%" }} />;
  }
}
