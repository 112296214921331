import { PlotlyGraph, PlotlyGraphProps } from "./PlotlyGraph";

const QUARTILE_LABELS = ["1ST (Lower)", "2ND", "3RD", "4TH (Upper)"];

export interface NormalDistributionGraphProps extends PlotlyGraphProps {
  subject_name: string
  subject_score: number
  mean_text?: string
  leaders_value?: number
  laggards_value?: number
  mean_value?: number
  quartiles?: number[] | number[][]
}

export class NormalDistributionGraph<
  Props extends NormalDistributionGraphProps
> extends PlotlyGraph<Props> {
  empty_graph_message = "*Insufficient industry data for comparative analysis";

  xAxisAttrs() {
    return {
      range: [this.startX, this.endX]
    };
  }

  private _quartiles: number[] | undefined;

  get quartiles(): number[] {
    if (this._quartiles) { return this._quartiles; }
    let value;
    const flatQuartiles = this.props.quartiles?.flat() ?? [];

    switch (flatQuartiles.length) {
      case 0:
        value = [];
        break;
      case 3:
        value = flatQuartiles;
        break;
      case 8:
        value = [flatQuartiles[1], flatQuartiles[3], flatQuartiles[5]];
    }
    this._quartiles = value;
    return value;
  }

  get mean_text() {
    return this.props.mean_text ?? "Average";
  }

  get leaders_value() {
    return this.props.leaders_value ?? 80;
  }

  get laggards_value() {
    return this.props.laggards_value ?? 40;
  }

  get mean_value() {
    return this.props.mean_value ?? this.mean(this.x);
  }

  mean(numbers: number[]) {
    return numbers.reduce((a, b) => a + b, 0) / numbers.length;
  }

  trace_area(xMin, xMax, fillColor = "d6d6d6", lineColor = "gray") {
    const { x, y } = this;
    return {
      x: x.filter((n) => {
        return n > xMin && n < xMax;
      }),
      y: y.filter((y, i) => x[i] > xMin && x[i] < xMax),
      type: "area",
      fillcolor: fillColor,
      fill: "tozeroy",
      line: { color: lineColor }
    };
  }

  trace_laggards() {
    return this.trace_area(this.startX - 10, this.laggards_value + 1);
  }

  trace_leaders() {
    return this.trace_area(this.leaders_value - 1, this.endX);
  }

  trace_mean() {
    return this.trace_area(this.laggards_value, this.leaders_value, "0082DE", "black");
  }

  get subject_label() {
    const y = this.yAtPos(this.props.subject_score);
    const { maxY } = this;

    return {
      x: this.props.subject_score,
      y,
      xref: "x",
      yref: "y",
      text: this.props.subject_name,
      showarrow: true,
      font: { stroke: "white", fill: "gray", strokeWidth: 10, paintOrder: "stroke fill" },
      arrowside: "none",
      ax: 0,
      ay: y >= maxY * 0.9 ? 20 : -20
    };
  }

  get mean_label() {
    const mean = this.mean_value;
    return {
      x: mean,
      y: this.yAtPos(mean),
      xref: "x",
      yref: "y",
      text: this.mean_text,
      arrowsize: 0,
      showarrow: true,
      arrowside: "none",
      arrowhead: 2,
      ax: 0,
      ay: 50
    };
  }

  get leaders_label() {
    const { y } = this;
    return {
      xref: "x",
      yref: "y",
      x: this.leaders_value,
      xanchor: "left",
      y: y[2],
      yanchor: "bottom",
      text: "Leaders",
      showarrow: false
    };
  }

  get laggards_label() {
    const { y } = this;
    return {
      xref: "x",
      yref: "y",
      x: this.laggards_value,
      xanchor: "right",
      y: y[2],
      yanchor: "bottom",
      text: "Laggards",
      showarrow: false
    };
  }

  get x_axis_label() {
    return {
      xref: "x",
      yref: "y",
      x: 25,
      xanchor: "right",
      y: 0,
      text: "Score",
      showarrow: false,
      ax: 100,
      ayref: "pixel",
      yshift: -8
    };
  }

  get yTick() {
    return this.maxY / this.y.length;
  }

  trace_quartiles() {
    const out: any[] = [];

    for (const q of this.quartiles) {
      out.push({
        x: [q, q],
        y: [0, this.yAtPos(q) - this.yTick * 3],
        mode: "lines",
        line: { color: "white", width: 2 }
      });
    }
    return out;
  }

  quartilePositions(): any[] {
    if (!this.quartiles.length) {
      return [];
    }
    const out: any[] = [];
    let start, end, n;
    n = 1;
    [start, end] = [0, this.quartiles[0]];
    const sky = this.maxY * 1.14;
    while (n <= this.quartiles.length + 1) {
      out.push({
        x: [start, end],
        y: [sky, sky]
      });
      [start, end] = [end, this.quartiles[n++] || this.endX];
    }
    return out;
  }

  quartileLabel(xPos, yPos, label: string) {
    return {
      xref: "x",
      yref: "y",
      x: xPos,
      mode: "text",
      font: {
        color: "white"
      },
      xanchor: "center",
      y: this.maxY * 1.0658,
      yanchor: "middle",
      text: `<b>${label}</b>`,
      showarrow: false
    };
  }

  quartileBarLabels() {
    const out: any[] = [];
    let n = 0;
    const quartilePositions = this.quartilePositions();
    while (n < quartilePositions.length) {
      const pos = quartilePositions[n];
      const initialPos: number = pos.x[0] || this.startX;
      const x = initialPos + (pos.x[1] - initialPos) / 2;
      const y = pos.y[1];
      out.push(this.quartileLabel(x, y, QUARTILE_LABELS[n++]));
    }
    return out;
  }

  quartileBar(highlight = null as number | null) {
    const out: any[] = [];
    let n = 0;
    for (const pos of this.quartilePositions()) {
      let color = "#cbcbcb";
      if (n++ == highlight) {
        color = "#62aee4";
      }
      out.push({
        x: [pos.x[0], pos.x[1] - this.startX / 100],
        y: pos.y,
        mode: "lines",
        line: { color, width: 93 }
      });
    }
    return out;
  }

  traces() {
    return [
      this.trace_leaders(),
      this.trace_laggards(),
      this.trace_mean(),
      ...this.trace_quartiles(),
      ...this.quartileBar()
    ];
  }

  annotations() {
    return [
      this.subject_label,
      this.leaders_label,
      this.laggards_label,
      this.mean_label,
      ...this.quartileBarLabels(),
      this.x_axis_label
    ];
  }
}
